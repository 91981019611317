@import '../../styles/dimensions';
@import '../../styles/breakpoints';

.item {
    align-items: center;
    aspect-ratio: 1/ 1;
    background-color: #1e1e1e;
    border-radius: $gap-medium;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: $gap-small;

    &:hover {
        filter: brightness(105%);
    }

    @include screen-max-width('sm') {
        border-radius: $gap-small;
        padding: $gap-x-small;
    }
}

.item::before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: calc(3 / 3 * 100%);
    width: 0;
}

.imageContainer {
    align-items: center;
    display: flex;
    height: auto !important;
    justify-content: center;
    margin: auto;
    max-width: 70px;

    @include screen-max-width('sm') {
        max-width: 50px;
    }
}

.centerBlockImageContainer {
    max-width: none;
}

.image {
    height: auto;
    width: 100%;
}

.title div p {
    font-size: 34px;
    font-weight: 900;
    letter-spacing: -0.515px;
    line-height: 1;
    margin: $gap-x-small auto;
    text-align: center;
    width: 100%;

    @include screen-max-width('sm') {
        font-size: 15px;
    }
}

.subtitle div p {
    font-size: 18px;
    font-weight: 400;
    margin: auto;
    text-align: center;
    width: 100%;

    @include screen-max-width('sm') {
        font-size: 13px;
    }
}
